.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footer{
  width: 100%;
  margin-top: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.add-cursor {
  cursor: pointer;
}
.icon-size {
  width: 30px;
  height: 40px;
}

